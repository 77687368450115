import React from 'react'
import { Flex, WhiteSpace, Toast, ActivityIndicator } from 'antd-mobile'
import queryString from 'query-string'
import { format } from 'date-fns'
import find from 'lodash/find'
import TaskForm from './component/TaskForm'

class CreateTask extends React.Component {
    constructor() {
        super()

        this.state = {
            task: {},
            peoples: [],
            boards: [],
            messages: [],
            error: false,
            isLoading: true
        }

        const queryObj = queryString.parse(window.location.search)

        this.taskId = queryObj.id || 0
        this.groupId = queryObj.b || 0

        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleOnChangeBoard = this.handleOnChangeBoard.bind(this)
    }

    componentDidMount() {
        if (this.taskId === 0 && this.groupId === 0) {
            this.getProfile()
        } else {
            this.getTask()
            this.getBoardUsers(this.groupId)
            this.readTask()
        }
    }

    handleOnSubmit(values, resetForm) {
        this.setState({
            isLoading: true
        })

        let url = this.groupId === 0 && this.taskId === 0 ? `/board/${values.board}/tasks` : `/board/${this.groupId}/task/${this.taskId}`
        const data = {
            ...values,
            userId: this.props.userId
        }
        if (this.groupId === 0 && this.taskId === 0) {
            data.owners = values.owners.map(o => {
                return find(this.state.peoples, { id: o }).code
            })

        }

        const method = this.groupId === 0 && this.taskId === 0 ? 'post' : 'put'
        this.props.request[method](url, data).then(res => {
            resetForm()
            const task = this.groupId === 0 && this.taskId === 0 ? {} : res
            this.setState({
                task,
                isLoading: false,
                peoples: []
            })
            if (this.props.liff) {
                this.props.liff.closeWindow()
            } else {
                Toast.success(this.groupId === 0 && this.taskId === 0 ? '新增城功' : '編輯成功', 3)
            }

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    handleOnChangeBoard(groupId) {
        this.setState({
            isLoading: true,
            peoples: []
        })
        this.getBoardUsers(groupId)
    }

    readTask() {
        if (this.taskId === 0) {
            return
        }

        this.props.request.post(`/board/${this.groupId}/task/${this.taskId}/read`).then(() => {})
    }

    getProfile() {
        this.props.request.get(`/user/profile`)
        .then(data => {
            this.setState({
                boards: data.map(b => ({
                    value: b.code,
                    label: b.name === null || b.name === '' ? b.id : b.name
                })),
                isLoading: false
            })

        }).catch(e => console.log(e))
    }

    getBoardUsers(groupId) {
        if (groupId === 0) {
            return
        }

        this.props.request.get(`/board/${groupId}/users`)
        .then(data => {
            this.setState({
                peoples: data,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                error: e,
                isLoading: false
            })
        })
    }

    getTask() {
        if (this.taskId === 0) {
            return
        }

        this.props.request.get(`/board/${this.groupId}/task/${this.taskId}`)
        .then(data => {
            this.setState({
                task: data
            })
            this.getPrevMessages(data.group_id, format(data.created_at, 'YYYY-MM-DD HH:mm'))

        }).catch(e => {
            this.setState({
                error: e
            })
        })
    }

    getPrevMessages(groupId, date) {
        //const date = format(new Date(), 'YYYY-MM-DD HH:mm')
        this.props.request.get(`/board/${groupId}/messages?timestamp=${date}`)
        .then(data => {
            this.setState({
                messages: data
            })

        }).catch(e => console.log(e))
    }

    render() {
        const { error, task, isLoading, peoples, messages, boards } = this.state

        return (
            <div>
                <ActivityIndicator size="large" animating={isLoading} toast={true} />
                <WhiteSpace />
                <Flex justify="center">
                    <h2>{!error ? (this.taskId !== 0 ? '編輯任務' : '建立任務') : error}</h2>
                </Flex>
                {!error ? <TaskForm
                            liff={this.props.liff}
                            task={task}
                            peoples={peoples}
                            boards={boards}
                            messages={messages}
                            onSubmit={this.handleOnSubmit}
                            onChangeBoard={this.handleOnChangeBoard}
                /> : null}
                <WhiteSpace size="lg"/>
            </div>
        )
    }

}

export default CreateTask
