import React from 'react'
import { Accordion, Button } from 'antd-mobile'
import { format, isAfter } from 'date-fns'
import * as util from '../lib/util'

const getPanelTitle = (title, status, dueDate, extraValue = '') => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '15px',
            paddingRight: '35px',
            backgroundColor: getBackgroundColor(status, dueDate),
            fontWeight: 600
        }}
    >
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</span>
        <span style={{ fontSize: '14px' }}>{extraValue}</span>
    </div>
)

const getBackgroundColor = (status, dueDate) => {
    const today = format(new Date(), 'YYYY-MM-DD')
    if (dueDate !== null
        && status !== 4 && status !== 3
        && isAfter(today, dueDate))
    {
        return '#ef9a9a'
    }

    switch (status) {
        case 0:
            return '#EEEEEE'
        case 1:
            return '#C5E1A5'
        case 2:
            if (dueDate === null || isAfter(dueDate, today)) {
                return '#C5E1A5' // green
            } else {
                return '#ef9a9a' // red
            }
        case 3:
            return '#B4D8E7'
        case 4:
            return '#BCAAA4'
        default:
            return '#EEEEEE'
    }
}

const Tasks = ({ tasks, onEditVisible, onEditStatus, extraKey = 'board_id', keyPrefix = '' }) => (
    <Accordion>
        {tasks.map(task =>
            <Accordion.Panel key={`${keyPrefix}_${task.board_id}_${task.id}`} header={getPanelTitle(task.subject, task.status, task.due_date, task.due_date !== null ? format(task.due_date, 'YYYY-MM-DD') : '')}>
                <div style={{ backgroundColor: getBackgroundColor(task.status, task.due_date), margin: '-10px' }}>
                    <div style={{ padding: '10px' }}>
                        <div>群組名稱：{task.board_name || ''}</div>
                        <div>任務描述：{task.desc}</div>
                        <div>截止日期：{task.due_date !== null ? format(task.due_date, 'YYYY-MM-DD') : ''}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            狀態：{util.getStatusDesc(task.status)}
                            {task.status !== 1 ? <Button size="small" type="primary" style={{ marginLeft: '10px' }} onClick={() => onEditStatus(task, 1)}>已新增</Button> : null}
                            {task.status !== 2 ? <Button size="small" type="primary" style={{ marginLeft: '10px' }} onClick={() => onEditStatus(task, 2)}>進行中</Button> : null}
                            {task.status !== 3 ? <Button size="small" type="primary" style={{ marginLeft: '10px' }} onClick={() => onEditStatus(task, 3)}>已完成</Button> : null}
                            {task.status !== 4 ? <Button size="small" type="primary" style={{ marginLeft: '10px' }} onClick={() => onEditStatus(task, 4)}>已取消</Button> : null}
                        </div>
                        <div>
                            <div>負責人：</div>
                            {task.owners ? task.owners.map((u, idx) =>
                                <span key={idx}>{u.name}{idx < (task.owners.length - 1) ? '、' : ''}</span>
                            ) : null}
                        </div>
                        <div>發起日期：{format(task.created_at, 'YYYY-MM-DD')}</div>
                        <Button type="primary" onClick={() => onEditVisible(task)}>編輯</Button>
                    </div>
                </div>
            </Accordion.Panel>)
        }
    </Accordion>
)

export default Tasks
