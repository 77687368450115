import React from 'react'
import {
    WhiteSpace,
    List,
    TextareaItem,
    Picker,
    DatePicker,
    Button,
    LocaleProvider,
    Checkbox,
    Accordion,
    Flex
} from 'antd-mobile'
import { createForm } from 'rc-form'
import enUS from 'antd-mobile/lib/locale-provider/en_US'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import without from 'lodash/without'
import { format, parse, addMonths } from 'date-fns'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import Select from 'react-select'
import * as util from '../lib/util'

class TaskForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            owners: [],
            title: null,

            board: '',
            status: 0,
            dueDate: '',
        }

        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleOpenTaskList = this.handleOpenTaskList.bind(this)
        this.handleOnWebFieldsChange = this.handleOnWebFieldsChange.bind(this)
        this.setTitle = this.setTitle.bind(this)
        this.parseDate = this.parseDate.bind(this)
        this.formatDate = this.formatDate.bind(this)
    }

    handleOnSubmit() {
        const { owners } = this.state
        if (owners.length === 0) {
            return
        }
        this.props.form.validateFields((error, value) => {
            if (!error && (this.props.liff || (!this.props.liff && this.state.board !== ''))) {
                let dueDate = this.props.liff ? value.dueDate : this.state.dueDate
                dueDate = dueDate !== null && dueDate !== '' ? format(dueDate, 'YYYY-MM-DD') : null
                const params = {
                    title: value.title,
                    description: value.description,
                    dueDate,
                    owners,
                    status: this.props.liff ? value.status[0] : this.state.status
                }
                if (isEmpty(this.props.task)) {
                    params.board = this.props.liff ? value.board[0] : this.state.board
                }
                this.props.onSubmit(params, () => {
                    if (!this.props.liff) {
                        this.props.form.resetFields()
                        this.setState({
                            owners: [],
                            status: 1,
                            board: '',
                            dueDate: null
                        })
                    }
                })
            }
        });
    }

    handleOnChange(e, o) {
        let { owners } = this.state
        if (e.target.checked) {
            if (owners.indexOf(o) === -1) {
                owners.push(o)
            }
        } else {
            owners = without(owners, o)
        }

        this.setState({
            owners
        })
    }

    handleOnWebFieldsChange(type, value) {
        if (type === 'board') {
            this.props.onChangeBoard(value)
        }
        this.setState({
            [type]: value
        })
    }

    handleOnSetMessage(e, message) {
        if (!e.target.checked) {
            return
        }

        const { getFieldValue, setFieldsValue } = this.props.form
        const orgDesc = getFieldValue('description')
        setFieldsValue({
            description: orgDesc + '\n' + message
        })
    }

    handleOpenTaskList() {
        this.props.onOpenTaskList()
    }

    setTitle(el) {
        this.setState({
            title: el
        })
    }

    getCurrentReadLog(logs, userId) {
        const log = find(logs, { code: userId, type: 1 })
        return log !== undefined ? format(log.created_at, 'YYYY-MM-DD HH:mm') : ''
    }

    parseDate(str) {
        const parsed = parse(str, 'YYYY-MM-DD');
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    formatDate(date) {
        return format(date, 'YYYY-MM-DD');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {}

        if (prevState.title
            && prevState.owners.length === 0
            && (nextProps.task.owners && nextProps.task.owners.length > 0)
        ) {
            prevState.title.focus()
        }

        newState.board = nextProps.task ? nextProps.task.group_id : prevState.group_id
        if (nextProps.task.status) {
            newState.status = prevState.status === 0 ? nextProps.task.status : prevState.status
        } else if (prevState.status === 0) {
            newState.status = 1
        } else {
            newState.status = prevState.status
        }

        if (nextProps.task.due_date) {
            newState.dueDate = prevState.dueDate === '' ? nextProps.task.due_date : prevState.dueDate
        }
        if (nextProps.task.owners && prevState.owners.length === 0) {
            newState.owners = [...nextProps.task.owners]
        }

        return newState
    }

    render () {
        const { owners } = this.state
        const { task, peoples,  messages = [], boards = [], onChangeBoard = () => {} } = this.props
        const { getFieldProps, getFieldError } = this.props.form
        const titleErr = getFieldError('title')
        const boardErr = getFieldError('board') || this.props.form.getFieldValue('board') === undefined

        return (
            <LocaleProvider locale={enUS}>
                <List>
                {boards.length > 0 ?
                    <div>
                        {this.props.liff ?
                            <Picker
                                {...getFieldProps('board', {
                                    rules: [{ required: true }],
                                    onChange(v) {
                                        onChangeBoard(v[0])
                                    }
                                })}
                                data={boards}
                                cols={1}
                            >
                                <List.Item error={boardErr} arrow="horizontal">群組</List.Item>
                            </Picker>
                        : <List.Item id="webBoard">
                            <Flex justify="between">
                                群組
                                <Select
                                    value={this.state.board}
                                    onChange={b => this.handleOnWebFieldsChange('board', b.value)}
                                    options={boards}
                                    className="board"
                                    placeholder="請選擇"
                                />
                            </Flex>
                            {this.state.board === '' ? <div style={{ color: '#FF5500', paddingLeft: '59px' }}>請選擇群組</div> : ''}
                        </List.Item>}
                    </div> : null}
                    <TextareaItem
                        title="標題"
                        autoHeight
                        error={titleErr}
                        maxLength={128}
                        {...getFieldProps('title', {
                            rules: [
                                {
                                    required: true
                                }
                            ],
                            initialValue: task.subject || '',
                            ref: this.setTitle
                        })}
                    >
                        任務標題
                    </TextareaItem>
                    <TextareaItem
                        {...getFieldProps('description', {
                            initialValue: task.desc || ''
                        })}
                        count={256}
                        title="任務內容"
                        rows={4}
                    />
                    {messages.length > 0 ?
                        <Accordion>
                            <Accordion.Panel header={<div className="prev-message-title">過往訊息</div>}>
                                <List>
                                    {messages.map((m, idx) =>
                                        <Checkbox.CheckboxItem
                                            key={`prev_message_${idx}`}
                                            onChange={e => this.handleOnSetMessage(e, m.message)}
                                        >
                                            <div>
                                                <div>{m.name}</div>
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{m.message}</div>
                                            </div>
                                        </Checkbox.CheckboxItem>
                                    )}
                                </List>
                            </Accordion.Panel>
                        </Accordion> : null}
                    {peoples.length > 0 ?
                        <div id="peoples">
                            <List.Item error={owners.length === 0} extra={owners.length === 0 ? '請選擇負責人' : ''}>負責人</List.Item>
                            {peoples.map(i => (
                                i.code !== 'U1d1c701baedb3380581181dd5dac01a2' ?
                                <Checkbox.CheckboxItem
                                    key={i.code}
                                    onChange={e => this.handleOnChange(e, i.id)}
                                    checked={owners ? owners.indexOf(i.id) !== -1 : false}
                                    extra={!isEmpty(task.logs) ? this.getCurrentReadLog(task.logs, i.code) : ''}
                                >
                                    {i.name === null || i.name === '' ? i.code : i.name}
                                </Checkbox.CheckboxItem> : null
                            ))}
                    </div> : null}
                    {task.task_creator_info ?
                        <Accordion>
                            <Accordion.Panel header={<div className="prev-message-title">更多細節</div>}>
                                <List>
                                    <List.Item extra={task.task_creator_info.name}>發起人</List.Item>
                                    <List.Item extra={format(task.created_at, 'YYYY-MM-DD')}>發起日期</List.Item>
                                    <List.Item extra={!isEmpty(task.resolved_at) ? format(task.resolved_at, 'YYYY-MM-DD') : ''}>完成日期</List.Item>
                                    <List.Item>群組管理人</List.Item>
                                </List>
                            </Accordion.Panel>
                        </Accordion> : null }
                    {this.props.liff ?
                        <DatePicker
                            mode="date"
                            minDate={new Date()}
                            maxDate={addMonths(new Date(), 6)}
                            {...getFieldProps('dueDate', { initialValue: task.due_date ? parse(task.due_date) : null })}>
                            <List.Item arrow="horizontal">截止日期</List.Item>
                        </DatePicker> :
                        <List.Item id="webDueDate">
                            <Flex justify="between" style={{ overflow: 'visible' }}>
                                截止日期
                                <DayPickerInput
                                    value={this.state.dueDate}
                                    placeholder="請選擇"
                                    onDayChange={day => this.handleOnWebFieldsChange('dueDate', day)}
                                    format="YYYY-MM-DD"
                                    formatDate={this.formatDate}
                                    parseDate={this.parseDate}
                                    dayPickerProps={{
                                        disabledDays: {
                                            before: new Date(),
                                        }
                                    }}
                                />
                            </Flex>
                        </List.Item>}
                    {this.props.liff ? <Picker
                        {...getFieldProps('status', {
                            rules: [
                                {
                                    required: true
                                }
                            ],
                            initialValue: task.status && task.status > 0 ? [task.status] : [1]
                        })}
                        data={util.getStatusOptions()}
                        cols={1}
                    >
                        <List.Item arrow="horizontal">狀態</List.Item>
                    </Picker> :
                        <List.Item id="webStatus">
                            <Flex justify="between">
                                狀態
                                <Select
                                    onChange={s => this.handleOnWebFieldsChange('status', Number(s.value))}
                                    options={util.getStatusOptions()}
                                    className="status"
                                    placeholder="請選擇"
                                    value={find(util.getStatusOptions(), { value: this.state.status })}
                                />
                            </Flex>
                        </List.Item>}
                    <WhiteSpace/>
                    <Button
                        type="primary"
                        onClick={this.handleOnSubmit}
                        style={{ width: '100%' }}
                    >送出
                    </Button>
                    <WhiteSpace/>
                </List>
            </LocaleProvider>
        )
    }
}

export default createForm()(TaskForm)
