import React from 'react'
import Dock from 'react-dock'
import isEmpty from 'lodash/isEmpty'
import { Flex, Icon } from 'antd-mobile'

import TaskForm from './TaskForm'


class EditDock extends React.Component {
    constructor() {
        super()
        this.handleOnClose = this.handleOnClose.bind(this)
    }

    handleOnClose() {
        this.props.onClose()
    }

    render() {
        const { liff, isVisible, task, peoples, messages, onSubmit, zIndex = 1 } = this.props

        return (
            <div>
                <Dock
                    position="right"
                    isVisible={isVisible}
                    fluid={true}
                    size={liff ? 1 : 0.5}
                    zIndex={zIndex}
                >
                    <Flex justify="center" align="center">
                        <h2>編輯任務</h2>
                        <div onClick={this.handleOnClose} style={{ position: 'absolute', left: '10px' }}>
                            {liff ? <Icon type="left" /> : <Icon type="cross" />}
                        </div>
                    </Flex>
                    {!isEmpty(task) ?
                        <TaskForm
                            liff={liff}
                            task={task}
                            peoples={peoples}
                            messages={messages}
                            onSubmit={onSubmit}
                        /> : null}
                </Dock>
            </div>
        )
    }
}

export default EditDock
