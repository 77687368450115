import React from 'react'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { ActivityIndicator, Toast } from 'antd-mobile'
import Request from './lib/request'

const inputFields = ['a', 'b', 'c', 'd', 'e', 'f']

class Verify extends React.Component {
    constructor() {
        super()

        this.state = {
            isDebug: process.env.REACT_APP_IS_DEBUG,
            code: [],
            canSubmit: false,
            isLoading: false,
            userId: '',
            request: null,
            liff: null
        }

        inputFields.forEach(input => this[input] = null)

        this.handleOnSubmit = this.handleOnSubmit.bind(this)
    }

    componentDidMount() {
        if (window.liff) {
            window.liff.init(
                data => {
                    this.setState({
                        userId: data.context.userId,
                        request: new Request(data.context.userId, '', {
                            'X-Line-User-ID': data.context.userId
                        }),
                        liff: window.liff
                    })
                },
                err => {

                }
            )
        }
    }

    handleOnKeyUp(e) {
        const next = inputFields.indexOf(e)
        if (next !== inputFields.length - 1) {
            this[inputFields[next + 1]].focus()
        }
    }

    handleOnChange(idx, val) {
        const { code } = this.state
        let canSubmit = false
        code[idx] = val
        if (code.length === 6 && filter(code, v => !isEmpty(v)).length === 6) {
            canSubmit = true
        }
        this.setState({
            code,
            canSubmit
        })
    }

    handleOnSubmit() {
        const { code, request, liff } = this.state
        request.post(`/auth/login`, {
            verify_code: code.join('')

        }).then(() => {
            this.setState({
                isLoading: false
            })
            liff.closeWindow()

        }).catch(e => Toast.fail(e, 3))

    }

    render() {
        const { canSubmit, isLoading, liff } = this.state

        return (
            <div id="verify">
                {liff ?
                    <div>
                        <ActivityIndicator size="large" animating={isLoading} toast={true} />
                        <div className="title">
                            請輸入於登入頁看到的數字（共六碼）
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            {inputFields.map((input, idx) =>
                                <input
                                    key={input}
                                    ref={e => this[input] = e}
                                    maxLength="1"
                                    size="2"
                                    type="tel"
                                    pattern="\d*"
                                    onKeyUp={e => { if (e.keyCode !== 8) this.handleOnKeyUp(input)}}
                                    onChange={e => this.handleOnChange(idx, e.target.value)}
                                ></input>
                            )}
                        </div>
                        <button onClick={this.handleOnSubmit} disabled={!canSubmit}>確認</button>
                    </div>
                : <h4>請於 LINE 中開啟此頁</h4>}
            </div>
        )
    }
}

export default Verify
