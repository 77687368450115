import axios from 'axios'
import queryString from 'query-string'

function sendRequest(req, method, url, params = null) {
    return new Promise((resolve, reject) => {
        req({
            method,
            url,
            data: params
        }).then(res => {
            if (res.data.status === 'SUCCESS') {
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        }).catch(e => {
            console.log(e)
            reject('server error')
        })
    })
}

class Request {
    constructor(userId, env = '', header = {}) {
        this.userId = userId
        this.env = env
        this.isLIFF = true

        let token = ''
        try {
            token = window.liff.getAccessToken()
        } catch(e) {
            this.isLIFF = false
        }

        let baseURL = process.env.REACT_APP_API_URL
        baseURL += env !== '' ? `/${env}` : ''
        let headers = {
            'X-Requested-With': 'XMLHttpRequest'
        }
        if (env === 'liff' && this.isLIFF) {
            headers['X-Line-User-ID'] = userId
            headers['X-Line-Access-Token'] = token
        }
        headers = {
            ...headers,
            ...header
        }
        this.instance = axios.create({
            baseURL,
            headers,
            withCredentials: true
        })
        this.instance.interceptors.response.use(res => {
            return res
        }, err => {
            console.log(err)
            if (err.response.status === 401 && !this.isLIFF) {
                localStorage.setItem('redirectPage', window.location.pathname)
                // TODO: use react-router
                window.location.href = `/login?u=${this.userId}`
            }
        })
    }

    get(url, params = null) {
        if (params !== null) {
            url += '?' + queryString.stringify(params)
        }
        return sendRequest(this.instance, 'get', url)
    }

    post(url, params = null) {
        return sendRequest(this.instance, 'post', url, params)
    }

    put(url, params = null) {
        return sendRequest(this.instance, 'put', url, params)
    }
}

export default Request
