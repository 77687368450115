import React from 'react'
import {
    Flex,
    WhiteSpace,
    Toast,
    ActivityIndicator,
    Button,
    Modal,
    List,
    Picker,
    LocaleProvider,
    DatePicker,
    Tabs
} from 'antd-mobile'
import { createForm } from 'rc-form'
import enUS from 'antd-mobile/lib/locale-provider/en_US'
import queryString from 'query-string'
import { format, parse } from 'date-fns'
import findIndex from 'lodash/findIndex'
import isArray from 'lodash/isArray'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import EditDock from './component/EditDock'
import Tasks from './component/Tasks'
import './taskList.css'

class FilterForm extends React.Component {
    constructor() {
        super()
        this.state = {
            board: null
        }

        this.handleOnBoardChange = this.handleOnBoardChange.bind(this)
        this.handleOnFilter = this.handleOnFilter.bind(this)
    }

    handleOnBoardChange(v) {
        this.props.onGetPeoples(v[0])
        this.setState({
            board: v
        })
    }

    handleOnFilter() {
        const { board } = this.state
        this.props.form.validateFields((error, value) => {
            const params = {}
            if (board !== null) {
                params['creator'] = board[0]
            }
            Object.keys(value).forEach(k => {
                if (isArray(value[k])) {
                    value[k] = compact(value[k])
                }
                if (value[k] !== null)  {
                    if (isArray(value[k])) {
                        if (value[k].length > 0) params[k] = value[k][0]
                    } else {
                        params[k] = value[k]
                    }
                }
            })
            this.props.onApply(params)
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.filter.creator !== undefined
            && prevState.board === null
        ) {
            return {
                board: [nextProps.filter.creator]
            }
        }

        return {
            board: prevState.board
        }
    }

    render() {
        const { filterOptions, filter } = this.props
        const { getFieldProps } = this.props.form

        return (
            <LocaleProvider locale={enUS}>
                <List>
                    <Picker value={this.state.board} data={filterOptions.boards} cols={1} onChange={a => this.handleOnBoardChange(a)}>
                        <List.Item arrow="horizontal">Board</List.Item>
                    </Picker>
                    {/* <Picker data={util.getStatusOptions()} {...getFieldProps('status', { initialValue: [filter.status] || null })} cols={1}>
                        <List.Item arrow="horizontal">Status</List.Item>
                    </Picker> */}
                    <DatePicker
                        mode="date"
                        {...getFieldProps('start_date', { initialValue: filter.start_date ? parse(filter.start_date) : null })}>
                        <List.Item arrow="horizontal">Start date</List.Item>
                    </DatePicker>
                    <DatePicker
                        mode="date"
                        {...getFieldProps('end_date', { initialValue: filter.end_date ? parse(filter.end_date) : null })}>
                        <List.Item arrow="horizontal">End date</List.Item>
                    </DatePicker>
                    <Picker data={filterOptions.peoples} {...getFieldProps('task_creator', { initialValue: [filter.task_creator] || null })} cols={1} disabled={filterOptions.peoples.length === 0}>
                        <List.Item arrow="horizontal">Creator</List.Item>
                    </Picker>
                    <List.Item>
                        <Button type="primary" onClick={this.handleOnFilter}>Apply</Button>
                    </List.Item>
                </List>
            </LocaleProvider>
        )
    }
}

const Filter = createForm()(FilterForm)

class TaskList extends React.Component {
    constructor() {
        super()

        const queryObj = queryString.parse(window.location.search)
        this.type = queryObj.t && queryObj.t === 'assigned' ? 'assigned' : 'my'
        this.tabs = []
        if (this.type === 'my') {
            this.tabs = [
                { title: '未完成', sub: 'undone' },
                { title: '已結束', sub: 'done' }
            ]
        } else {
            this.tabs = [
                { title: '已新增', sub: 'new' },
                { title: '進行中', sub: 'in_progress' },
                { title: '已完成', sub: 'resloved' },
                { title: '已結束', sub: 'closed' }
            ]
        }

        this.state = {
            taskList: this.tabs.reduce((t, v) => {
                t[v.sub] = []
                return t
            }, {}),
            peoples: [],
            messages: [],
            currentTask: {},
            currentTab: this.type === 'my' ? 'undone' : 'new',
            filterOptions: {
                boards: [],
                peoples: []
            },
            pagination: {
                current_page: 1,
                next_page_url: null
            },
            filter: {},
            isLoading: true,
            isOpenEdit: false,
            isOpenFilter: false
        }

        this.handleOnEditVisible = this.handleOnEditVisible.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleOnOpenFilter = this.handleOnOpenFilter.bind(this)
        this.getBoardUsers = this.getBoardUsers.bind(this)
        this.getFilterPeoples = this.getFilterPeoples.bind(this)
        this.handleOnApply = this.handleOnApply.bind(this)
        this.handleOnEditStatus = this.handleOnEditStatus.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        if (this.props.userId === '') {
            return
        }
        window.addEventListener('scroll', this.handleScroll, false)
        this.getTaskList(this.type === 'my' ? 'undone' : 'new')
        this.getProfile()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 50) return
        if (!this.state.isLoading && this.state.pagination.next_page_url !== null) {
            const values = this.state.filter
            const params = { ...values }
            if (values.start_date) {
                params['start_date'] = format(values.start_date, 'YYYY-MM-DD 00:00:00')
            }
            if (values.end_date) {
                params['end_date'] = format(values.end_date, 'YYYY-MM-DD 23:59:59')
            }
            this.getTaskList(this.state.currentTab, this.pagination.current_page + 1, params)
        }
    }

    handleOnApply(values) {
        if (isEmpty(values)) {
            this.setState({
                isOpenFilter: false
            })
        }

        this.setState({
            filter: values,
            isLoading: true
        })

        const params = {...values}
        if (values.start_date) {
            params['start_date'] = format(values.start_date, 'YYYY-MM-DD 00:00:00')
        }
        if (values.end_date) {
            params['end_date'] = format(values.end_date, 'YYYY-MM-DD 23:59:59')
        }

        this.getTaskList(this.state.currentTab, 1, params)
    }

    handleOnOpenFilter() {
        this.setState({
            isOpenFilter: !this.state.isOpenFilter
        })
    }

    handleOnEditVisible(task = null) {
        if (task !== null) {
            this.setState({
                isLoading: true,
                currentTask: {}
            })
            this.getBoardUsers(task.board_creator)
            this.getTask(task.board_creator, task.id)
            this.getPrevMessages(task.board_creator, format(task.created_at, 'YYYY-MM-DD HH:mm'))
            this.readTask(task.board_creator, task.id)
        } else {
            this.setState({
                isOpenEdit: false,
                currentTask: {}
            })
        }
    }

    handleOnEditStatus(task, newStatus) {
        const params = {
            title: task.subject,
            dueDate: task.due_date,
            status: newStatus,
            owners: task.owners ? task.owners.map(o => o.id) : []
        }
        this.props.request.put(`/board/${task.board_creator}/task/${task.id}`, params)
        .then(() => {
            const { taskList, currentTab } = this.state
            const currentIdx = findIndex(taskList[currentTab], { id: task.id })
            taskList[currentTab][currentIdx].status = params.status
            this.setState({
                taskList
            })

        }).catch(e => {
            Toast.fail(e, 3)
        })
    }

    handleOnTabChange(tab) {
        this.setState({
            currentTab: tab.sub
        })
        if (isEmpty(this.state.taskList[tab.sub])) {
            this.getTaskList(tab.sub)
        }
    }

    handleEdit(values) {
        this.setState({
            isLoading: true
        })

        const { currentTask } = this.state
        this.props.request.put(`/board/${currentTask.group_id}/task/${currentTask.id}`, values)
        .then(data => {
            Toast.success('編輯完成！', 2)
            const { taskList, currentTab } = this.state
            const idx = findIndex(taskList[currentTab], { id: currentTask.id })
            taskList[currentTab][idx] = data
            this.setState({
                taskList,
                isOpenEdit: false,
                isLoading: false
            })

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    readTask(groupId, taskId) {
        this.props.request.post(`/board/${groupId}/task/${taskId}/read`)
        .then(() => {})
    }

    getBoardUsers(groupId) {
        this.props.request.get(`/board/${groupId}/users`)
        .then(data => {
            this.setState({
                peoples: data,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getFilterPeoples(groupId) {
        this.props.request.get(`/board/${groupId}/users`)
        .then(data => {
            this.setState({
                filterOptions: {
                    ...this.state.filterOptions,
                    peoples: data.map(p => ({
                        value: p.code,
                        label: p.name
                    }))
                }
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getTaskList(type, page = 1, params = null) {
        this.setState({
            isLoading: true
        })

       const isNeedReload = !isEmpty(params) && page === 1 ? true : false
        if (params !== null) {
            params['t'] = type
            params['page'] = page
        } else {
            params = {
                t: type,
                page
            }
        }

        this.props.request.get('user/tasks', params)
        .then(data => {
            const { taskList } = this.state
            if (data['data']) {
                if (isNeedReload) {
                    taskList[type] = data.data
                } else {
                    taskList[type] = [
                        ...this.state.taskList[type],
                        ...data.data
                    ]
                }
                this.setState({
                    taskList,
                    pagination: {
                        current_page: data.current_page,
                        next_page_url: data.next_page_url
                    },
                    isLoading: false,
                    isOpenFilter: false
                })

            } else {
                this.setState({
                    isOpenFilter: false,
                    isLoading: false
                })
            }

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    getTask(groupId, taskId) {
        this.props.request.get(`/board/${groupId}/task/${taskId}`)
        .then(data => {
            this.setState({
                currentTask: data,
                isOpenEdit: true,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getProfile() {
        this.props.request.get(`/user/profile`)
        .then(data => {
            this.setState({
                filterOptions: {
                    ...this.state.filterOptions,
                    boards: data.map(r => ({
                        value: r.code,
                        label: r.name || r.id
                    }))
                },
                isLoading: false
            })

        }).catch(e => console.log(e))
    }

    getPrevMessages(groupId, date) {
        this.props.request.get(`/board/${groupId}/messages?timestamp=${date}`)
        .then(data => {
            this.setState({
                messages: data
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {
            taskList,
            peoples,
            messages,
            currentTask,
            isLoading,
            isOpenEdit,
            isOpenFilter,
            filterOptions
        } = this.state
        const filters = this.state.filter

        return (
            <div className="taskList">
                <ActivityIndicator size="large" animating={isLoading} toast={true} />
                <WhiteSpace />
                <Flex justify="center">
                    <h2>{this.type === 'my' ? '待辦任務' : '我的交辦'}</h2>
                    <Button size="small" type="primary" style={{ position: 'absolute', right: '10px' }} onClick={this.handleOnOpenFilter}>篩選</Button>
                </Flex>
                {/* 待辦任務 */}
                {this.type === 'my' ?
                    <Tabs tabs={this.tabs} initialPage={0} onChange={tab => this.handleOnTabChange(tab)}>
                        <div>
                            <Tasks
                                tasks={taskList.undone}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                        <div>
                            <Tasks
                                tasks={taskList.done}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                    </Tabs> : null}
                {/* 我的交辦 */}
                {this.type === 'assigned' ?
                    <Tabs tabs={this.tabs} initialPage={0} onChange={tab => this.handleOnTabChange(tab)}>
                        {/* New */}
                        <div>
                            <Tasks
                                tasks={taskList.new}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                        {/* in progress */}
                        <div>
                            <Tasks
                                tasks={taskList.in_progress}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                        {/* resloved */}
                        <div>
                            <Tasks
                                tasks={taskList.resloved}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                        {/* cancel */}
                        <div>
                            <Tasks
                                tasks={taskList.closed}
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                    </Tabs> : null}
                <EditDock
                    liff={this.props.liff}
                    isVisible={isOpenEdit}
                    task={currentTask}
                    peoples={peoples}
                    messages={messages}
                    onSubmit={this.handleEdit}
                    onClose={this.handleOnEditVisible}
                />
                <Modal
                    popup
                    visible={isOpenFilter}
                    onClose={this.handleOnOpenFilter}
                    animationType="slide-up"
                >
                    <Filter
                        filter={filters}
                        filterOptions={filterOptions}
                        onGetPeoples={this.getFilterPeoples}
                        onApply={this.handleOnApply}
                    />
                </Modal>
            </div>
        )
    }
}

export default TaskList
