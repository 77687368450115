import React from 'react'
import {
    Flex,
    WhiteSpace,
    ActivityIndicator,
    List,
    Tabs,
    Toast,
    Icon,
    Button
} from 'antd-mobile'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import Dock from 'react-dock'
import { format } from 'date-fns'
import EditDock from './component/EditDock'
import Tasks from './component/Tasks'

const tabs = [
    {
        title: '未完成',
        sub: 'in_progress'
    },
    {
        title: '已結束',
        sub: 'closed'
    }
]

class Profile extends React.Component {
    constructor() {
        super()

        this.state = {
            me: {},
            profile: [],
            peoples: {},
            taskList: {
                'in_progress': [],
                'closed': []
            },
            pagination: {
                current_page: 1,
                next_page_url: null
            },
            messages: [],

            currentTab: 'in_progress',
            currentBoardId: null,
            currentTask: {},
            currentMember: {},

            isLoading: true,
            isOpenBoardDetail: false,
            isOpenBoardUserTaskList: false,
            isOpenEdit: false,
            isEditBoardName: false,

            boardName: ''
        }

        this.handleOnEditVisible = this.handleOnEditVisible.bind(this)
        this.handleOnCloseBoardDetail = this.handleOnCloseBoardDetail.bind(this)
        this.handleOnEditStatus = this.handleOnEditStatus.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleOnOpenModify = this.handleOnOpenModify.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnModyBoardName = this.handleOnModyBoardName.bind(this)
        this.handleOnCancelModyBoardName = this.handleOnCancelModyBoardName.bind(this)
        this.handleScroll = this.handleScroll.bind(this)

        this.list = null
    }

    componentDidMount() {
        this.list.parentElement.style.overflow = 'hidden'
        document.querySelectorAll('.am-tabs-pane-wrap').forEach(e => {
            e.addEventListener('scroll', this.handleScroll, false)
        })

        this.getUser()
        this.getUserBoards()
    }

    componentWillUnmount() {
        document.querySelectorAll('.am-tabs-pane-wrap').forEach(e => {
            e.removeEventListener('scroll', this.handleScroll)
        })
    }

    handleScroll(e) {
        const { profile, isLoading, pagination, currentBoardId, currentTab } = this.state
        const bottom = e.target.scrollHeight - e.target.offsetHeight - 50
        if (!isLoading && e.target.scrollTop >= bottom && pagination.next_page_url !== null) {
            const board = find(profile, { id: currentBoardId })
            this.getBoardTaskList(board.code, currentTab, pagination.current_page + 1)
        }
    }

    handleOnTabChange(tab) {
        this.setState({
            currentTab: tab.sub
        })
        if (isEmpty(this.state.taskList[tab.sub])) {
            const {currentBoardId, profile} = this.state
            const board = find(profile, { id: currentBoardId })
            this.getBoardTaskList(board.code, tab.sub)
        }
    }

    handleOnOpenDetail(board) {
        this.setState({
            currentBoardId: board.id,
            isOpenBoardDetail: true,
            taskList: {
                'in_progress': [],
                'closed': []
            }
        }, () => {
            this.getBoardTaskList(board.code)
        })
    }

    handleOnEditVisible(task = null) {
        if (task !== null) {
            this.setState({
                isLoading: true,
                currentTask: {}
            })

            const { profile, peoples, currentBoardId } = this.state
            const board = find(profile, { id: currentBoardId })
            if (isEmpty(peoples[currentBoardId])) {
                this.getBoardUsers(board.id, board.code)
            }
            this.getTask(board.code, task.id)
            this.getPrevMessages(board.code,  format(task.created_at, 'YYYY-MM-DD HH:mm'))
            this.readTask(board.code, task.id)
        } else {
            this.setState({
                isOpenEdit: false,
                currentTask: {}
            })
        }
    }

    handleOnCloseBoardDetail() {
        this.setState({
            isOpenBoardDetail: false,
            isEditBoardName: false
        })
    }

    handleOnEditStatus(task, newStatus) {
        const params = {
            title: task.subject,
            dueDate: task.due_date,
            status: newStatus,
            owners: task.owners ? task.owners.map(o => o.id) : []
        }
        this.props.request.put(`/board/${task.board_creator}/task/${task.id}`, params)
        .then(res => {
            const { taskList, currentTab } = this.state
            const currentIdx = findIndex(taskList[currentTab], { id: task.id })
            taskList[currentTab][currentIdx].status = params.status
            this.setState({
                taskList
            })

        }).catch(e => {
            Toast.fail(e, 3)
        })
    }

    handleEdit(values) {
        this.setState({
            isLoading: true
        })

        const { currentTask } = this.state
        this.props.request.put(`/board/${currentTask.group_id}/task/${currentTask.id}`, values)
        .then(data => {
            Toast.success('編輯完成！', 2)
            const { taskList, currentTab } = this.state
            const idx = findIndex(taskList[currentTab], { id: currentTask.id })
            taskList[currentTab][idx] = data
            this.setState({
                taskList,
                isOpenEdit: false,
                isLoading: false
            })

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    handleOnOpenUserTaskList(userId) {
        const { currentBoardId, peoples } = this.state
        const member = find(peoples[currentBoardId], { 'code': userId })
        this.setState({
            currentMember: member,
            isOpenBoardUserTaskList: true
        })
    }

    handleOnOpenModify() {
        this.setState({
            isEditBoardName: true,
            boardName: this.getBoardName()
        })
    }

    handleOnChange(e) {
        this.setState({
            boardName: e.target.value
        })
    }

    handleOnModyBoardName() {
        const { boardName, currentBoardId, profile } = this.state
        const board = find(profile, { id: currentBoardId })

        this.props.request.put(`/board/${board.code}`, { userId: this.props.userId, name: boardName })
        .then(data => {
            if (data.status === 1) {
                Toast.success('修改完成', 3)
                const boardIndex = findIndex(profile, { id: currentBoardId })
                board.name = boardName
                profile[boardIndex] = board
            } else {
                Toast.fail(data.reply_message, 3)
            }

            this.setState({
                profile,
                isLoading: false,
                isEditBoardName: false
            })

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false,
                isEditBoardName: false
            })
        })
    }

    handleOnCancelModyBoardName() {
        this.setState({
            isEditBoardName: false
        })
    }

    getBoardUsers(boardId, groupId) {
        const { peoples } = this.state
        this.props.request.get(`/board/${groupId}/users`)
        .then(data => {
            peoples[boardId] = data
            this.setState({
                peoples,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getUser() {
        this.props.request.get(`/user`)
        .then(me => {
            this.setState({
                me,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getUserBoards() {
        this.props.request.get(`/user/profile`)
        .then(data => {
            const peoples = {}
            data.map(r => r.id).forEach(id => {
                peoples[id] = []
            })
            this.setState({
                profile: data,
                peoples,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getTask(groupId, taskId) {
        this.props.request.get(`/board/${groupId}/task/${taskId}`)
        .then(data => {
            this.setState({
                currentTask: data,
                isOpenEdit: true,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getBoardTaskList(groupId, type = 'in_progress', page = 1) {
        this.setState({
            isLoading: true
        })

        this.props.request.get(`board/${groupId}/tasks?t=${type}&page=${page}`)
        .then(data => {
            const { taskList } = this.state
            if (data['data']) {
                taskList[type] = [
                    ...this.state.taskList[type],
                    ...data.data
                ]
                this.setState({
                    taskList,
                    pagination: {
                        current_page: data.current_page,
                        next_page_url: data.next_page_url
                    },
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
            }

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    getPrevMessages(groupId, date) {
        //const date = format(new Date(), 'YYYY-MM-DD HH:mm')
        this.props.request.get(`/board/${groupId}/messages?timestamp=${date}`)
        .then(data => {
            this.setState({
                messages: data
            })
        }).catch(e => console.log(e))
    }

    readTask(groupId, taskId) {
        this.props.request.post(`/board/${groupId}/task/${taskId}/read`).then(() => {})
    }

    getBoardName() {
        const { profile, currentBoardId } = this.state
        if (currentBoardId) {
            const p = find(profile, { id: currentBoardId })

            if (p && p.name) {
                return p.name
            } else {
                return p.members.map(m => m.name).join('、')
            }
        }

        return ''
    }

    render() {
        const {
            me,
            profile,
            taskList,
            peoples,
            messages,
            currentBoardId,
            currentTask,
            isLoading,
            isOpenBoardDetail,
            isOpenEdit,
            isEditBoardName,
            boardName
        } = this.state

        return (
            <div>
                <ActivityIndicator size="large" animating={isLoading} toast={true} />
                <WhiteSpace />
                <Flex justify="center">
                    <h2>群組清單</h2>
                </Flex>
                <List id="groupList">
                    {profile.map(p =>
                        <List.Item
                            key={p.id}
                            arrow="horizontal"
                            onClick={() => this.handleOnOpenDetail(p)}
                            multipleLine={true}
                            wrap={true}
                        >
                            {!isEmpty(p.name) ? p.name : p.members.map(m => m.name).join('、')}
                        </List.Item>
                    )}
                </List>
                <Dock
                    position="right"
                    isVisible={isOpenBoardDetail}
                    fluid={true}
                    size={1}
                    zIndex={1}
                >
                    <div id="boardList" ref={el => this.list = el} style={{ backgroundColor: '#F0F0F0' }}>
                        <Flex justify="center" align="center">
                            {!isEditBoardName ?
                                <h2>{this.getBoardName()}</h2> :
                                <Flex justify="center" align="center">
                                    <input value={boardName} style={{ margin: '10px 5px 10px 0', fontSize: '16px', lineHeight: 1.5 }} onChange={this.handleOnChange} />
                                    <Button type="primary" size="small" onClick={this.handleOnModyBoardName}>確定</Button>
                                    <Button size="small" onClick={this.handleOnCancelModyBoardName} style={{ marginLeft: '5px' }}>取消</Button>
                                </Flex>
                            }
                            <div onClick={this.handleOnCloseBoardDetail} style={{ position: 'absolute', left: '10px' }}>
                                <Icon type="left" />
                            </div>
                        </Flex>
                        {currentBoardId && !isEditBoardName && find(find(profile, { id: currentBoardId }).members, { is_owner: 1, id: me.id }) ? <div style={{ paddingBottom: '10px' }}><Button type="primary" size="small" style={{ width: '35%', left: '50%', transform: 'translateX(-50%)', marginTop: '-15px' }} onClick={this.handleOnOpenModify}>修改群組名稱</Button></div> : null}
                    </div>
                    <Tabs
                        tabs={tabs}
                        initialPage={0}
                        onChange={tab => this.handleOnTabChange(tab)}
                    >
                        <div className="taskList">
                            <Tasks
                                keyPrefix="task_list_in_progress"
                                tasks={taskList.in_progress}
                                extraKey="due_date"
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                        <div className="taskList">
                            <Tasks
                                keyPrefix="task_list_closed"
                                tasks={taskList.closed}
                                extraKey="due_date"
                                onEditVisible={this.handleOnEditVisible}
                                onEditStatus={this.handleOnEditStatus}
                            />
                        </div>
                    </Tabs>
                </Dock>
                <EditDock
                    liff={this.props.liff}
                    isVisible={isOpenEdit}
                    task={currentTask}
                    peoples={peoples[currentBoardId] || []}
                    messages={messages}
                    onSubmit={this.handleEdit}
                    onClose={this.handleOnEditVisible}
                    zIndex={3}
                />
            </div>
        )
    }
}

export default Profile
