import React from 'react'
import Echo from 'laravel-echo'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import { Redirect } from 'react-router-dom'
import Request from './lib/request'

class Login extends React.Component {
    constructor() {
        super()

        this.state = {
            code: '',
            isRedirect: false
        }

        const queryObj = queryString.parse(window.location.search)
        this.userId = queryObj.u || null
        this.groupId = queryObj.b || null
        this.request = new Request(this.userId)
    }

    componentDidMount() {
        if (this.groupId === null && this.userId === null) {
            return
        }

        window.io = require('socket.io-client');
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.REACT_APP_SOCKET_HOST
        });

        const params = {
            code: this.userId || this.groupId
        }
        this.request.post('/auth/captcha', params).then(data => {
            window.Echo
            .channel(`user_verify_${params.code}_${data.code}`)
            .listen('.user_login', e => {
                new Request(
                    e.user_id, '',
                    {
                        Authorization: `Bearer ${e.token}`,
                        'X-Line-User-ID': e.user_id
                    }).post('/auth/success').then(data => {
                        Cookies.set('user_id', e.user_id, { expires: 30 })

                        if (localStorage.getItem('redirectPage')) {
                            const page = localStorage.getItem('redirectPage')
                            localStorage.removeItem('redirectPage')
                            window.location.href = page

                        } else {
                            this.setState({
                                isRedirect: true
                            })
                        }
                })
            })
            this.setState({
                code: data.code
            })
        })
    }

    render() {
        const { code, isRedirect } = this.state

        if (isRedirect) {
            return <Redirect to={this.props.location.state.from} />
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <h2>請在與 soso 小秘書的選單中，找到「登入」的功能並於頁面輸入以下的數字：</h2>
                <h1>{code}</h1>
            </div>
        )
    }
}

export default Login;
