import React from 'react'
import {
    Flex,
    WhiteSpace,
    Toast,
    ActivityIndicator,
    Tabs
} from 'antd-mobile'
import queryString from 'query-string'
import { format, addDays, isAfter } from 'date-fns'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import EditDock from './component/EditDock'
import Tasks from './component/Tasks'
import './taskList.css'

class Report extends React.Component {
    constructor() {
        super()

        this.state = {
            taskList: [],
            peoples: [],
            messages: [],
            currentTask: {},
            isLoading: true,
            isOpenEdit: false,
            isOpenFilter: false
        }

        const queryObj = queryString.parse(window.location.search)
        this.groupId = queryObj.b || ''
        this.startDate = queryObj.s || format(new Date(), 'YYYY-MM-DD')
        this.endDate = queryObj.e || format(addDays(this.startDate, 6), 'YYYY-MM-DD')

        this.tabs = [
            { title: '未完成', sub: 'undone' },
            { title: '已完成', sub: 'resolved' },
            { title: '已逾期', sub: 'overdue' }
        ]

        this.handleOnEditVisible = this.handleOnEditVisible.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.getBoardUsers = this.getBoardUsers.bind(this)
        this.handleOnEditStatus = this.handleOnEditStatus.bind(this)
    }

    componentDidMount() {
        this.getTaskList()
    }

    handleOnEditVisible(task = null) {
        if (task !== null) {
            this.setState({
                isLoading: true
            })
            this.getBoardUsers(task.board_creator)
            this.getTask(task.board_creator, task.id)
            this.getPrevMessages(task.board_creator, format(task.created_at, 'YYYY-MM-DD HH:mm'))
            this.readTask(task.board_creator, task.id)
        } else {
            this.setState({
                isOpenEdit: false,
                currentTask: {}
            })
        }
    }

    handleOnEditStatus(task, newStatus) {
        const params = {
            title: task.subject,
            dueDate: task.due_date,
            status: newStatus,
            owners: task.owners ? task.owners.map(o => o.id) : []
        }
        this.props.request.put(`/board/${task.board_creator}/task/${task.id}`, params)
        .then(() => {
            const { taskList } = this.state
            const currentIdx = findIndex(taskList, { id: task.id })
            taskList[currentIdx].status = params.status
            this.setState({
                taskList
            })

        }).catch(e => {
            Toast.fail(e, 3)
        })
    }

    handleEdit(values) {
        this.setState({
            isLoading: true
        })

        const { currentTask } = this.state
        this.props.request.put(`/board/${currentTask.group_id}/task/${currentTask.id}`, values)
        .then(data => {
            Toast.success('編輯完成！', 2)
            const { taskList } = this.state
            const idx = findIndex(taskList, { id: currentTask.id })
            taskList[idx] = data
            this.setState({
                taskList,
                isOpenEdit: false,
                isLoading: false
            })

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    readTask(groupId, taskId) {
        this.props.request.post(`/board/${groupId}/task/${taskId}/read`).then(() => {})
    }

    getBoardUsers(groupId) {
        this.props.request.get(`/board/${groupId}/users`)
        .then(data => {
            this.setState({
                peoples: data,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getTaskList() {
        if (this.groupId === '') {
            return
        }

        this.props.request.get(`board/${this.groupId}/weeklyReport?start=${this.startDate}&end=${this.endDate}`)
        .then(data => {
            this.setState({
                taskList: data,
                isOpenFilter: false,
                isLoading: false
            })

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    getTask(groupId, taskId) {
        this.props.request.get(`/board/${groupId}/task/${taskId}`)
        .then(data => {
            this.setState({
                currentTask: data,
                isOpenEdit: true,
                isLoading: false
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    getPrevMessages(groupId, date) {
        this.props.request.get(`/board/${groupId}/messages?timestamp=${date}`)
        .then(data => {
            this.setState({
                messages: data
            })

        }).catch(e => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {
            taskList,
            peoples,
            messages,
            currentTask,
            isLoading,
            isOpenEdit,
        } = this.state

        return (
            <div className="taskList">
                <ActivityIndicator size="large" animating={isLoading} toast={true} />
                <WhiteSpace />
                <Flex justify="center" style={{ textAlign: 'center' }}>
                    <h2>{taskList.length > 0 ? taskList[0].board_name : ''} 週報<br/>{this.startDate} ~ {this.endDate}</h2>
                </Flex>
                <Tabs tabs={this.tabs} initialPage={0}>
                    <div>
                        <Tasks
                            tasks={filter(taskList, t => t.status === 1 || t.status === 2)}
                            onEditVisible={this.handleOnEditVisible}
                            onEditStatus={this.handleOnEditStatus}
                        />
                    </div>
                    <div>
                        <Tasks
                            tasks={filter(taskList, t => t.status === 3 || t.status === 4)}
                            onEditVisible={this.handleOnEditVisible}
                            onEditStatus={this.handleOnEditStatus}
                        />
                    </div>
                    <div>
                        <Tasks
                            tasks={filter(taskList, t => {
                                const today = format(new Date(), 'YYYY-MM-DD')
                                if (t.due_date !== null
                                    && t.status !== 4 && t.status !== 3
                                    && isAfter(today, t.due_date))
                                {
                                    return true
                                }
                            })}
                            onEditVisible={this.handleOnEditVisible}
                            onEditStatus={this.handleOnEditStatus}
                        />
                    </div>
                </Tabs>

                <EditDock
                    liff={this.props.liff}
                    isVisible={isOpenEdit}
                    task={currentTask}
                    peoples={peoples}
                    messages={messages}
                    onSubmit={this.handleEdit}
                    onClose={this.handleOnEditVisible}
                />
            </div>
        )
    }
}

export default Report
