import React from 'react'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import isNil from 'lodash/isNil'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import isEmpty from 'lodash/isEmpty'
import Request from '../lib/request'

const withPrivate = WrappedComponent =>
    class extends React.Component {
        constructor(props) {
            super(props)

            let userId = ''
            let request = null

            if (!isNil(Cookies.get('user_id')) && !isEmpty(Cookies.get('user_id'))) {
                userId = Cookies.get('user_id')
                request = new Request(userId, 'web')
            }

            this.state = {
                isInitialized: false,
                isDebug: process.env.REACT_APP_IS_DEBUG,
                userId,
                request,
                liff: null
            }
        }

        componentDidMount() {
            if (window.liff) {
                window.liff.init(
                    data => {
                        this.setState({
                            userId: data.context.userId,
                            request: new Request(data.context.userId, 'liff'),
                            liff: window.liff,
                            isInitialized: true
                        })
                    },
                    err => {
                        // LIFF initialization failed
                        this.setState({
                            isInitialized: true
                        })
                        if (!process.env.REACT_APP_IS_DEBUG) {
                            ReactGA.initialize('UA-149592628-1')
                            ReactGA.pageview(window.location.pathname);
                        }
                    }
                )
            }
        }

        render() {
            const { isInitialized, userId, liff } = this.state
            if (isInitialized && !liff && userId === '') {
                const queryObj = queryString.parse(window.location.search)
                if (!isNil(queryObj.b))
                {
                    return <Redirect to={{ pathname: '/login', search: `?b=${queryObj.b}`, state: { from: `${this.props.location.pathname}${window.location.search}` }}} />
                }
            }

            return (
                <div style={{ maxWidth: '520px', width: '100%', margin: '0 auto' }}>
                    {isInitialized && userId !== '' ? <WrappedComponent {...this.props} {...this.state} /> : null}
                </div>
            )
        }
    }

export default withPrivate
