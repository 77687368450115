import React from 'react'
import {
    Flex,
    WhiteSpace,
    ActivityIndicator,
    List,
    InputItem,
    Button,
    Toast
} from 'antd-mobile'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'

class EditBoard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            info: {},
            isLoading: true
        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnSubmit = this.handleOnSubmit.bind(this)

        const queryObj = queryString.parse(window.location.search)
        this.groupId = queryObj.b || ''
    }

    componentDidMount() {
        this.getGroupInfo()
    }

    handleOnChange(value) {
        this.setState({
            name: value
        })
    }

    getGroupInfo() {
        this.props.request.get(`/board/${this.groupId}`)
        .then(data => {
            if (isEmpty(data)) {
                Toast.fail('查無此群組！', 3)
                if (this.props.liff) {
                    this.props.liff.closeWindow()
                }
            } else {
                this.setState({
                    info: data,
                    name: data.name,
                    isLoading: false
                })
            }

        }).catch(e => {
            Toast.fail(e, 3)
        })
    }

    handleOnSubmit() {
        this.setState({
            isLoading: true
        })

        const { name } = this.state
        this.props.request.put(`/board/${this.groupId}`, { name })
        .then(data => {
            if (data.status === 1) {
                Toast.success('修改完成', 3)
            } else {
                Toast.fail(data.reply_message, 3)
            }

            this.setState({
                isLoading: false
            })
            if (this.props.liff) {
                this.props.liff.closeWindow()
            }

        }).catch(e => {
            Toast.fail(e, 3)
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const { name, isLoading } = this.state

        return (
            <div>
                <ActivityIndicator size="large" animating={isLoading} toast={true} />
                <WhiteSpace />
                <Flex justify="center">
                    <h2>編輯群組</h2>
                </Flex>
                <List>
                    <InputItem onChange={this.handleOnChange} error={name.trim() === ''} value={name}>群組名稱</InputItem>
                    <WhiteSpace size="lg" />
                    <Button type="primary" onClick={this.handleOnSubmit}>送出</Button>
                </List>
            </div>
        )
    }
}

export default EditBoard

