const status = {
    1: '已新增',
    2: '進行中',
    3: '已完成',
    4: '已取消'
}

export const getStatusOptions = () => {
    const options = []
    Object.keys(status).forEach(s =>
        options.push({
            value: Number(s),
            label: status[s]
        })
    )
    return options
}

export const getStatusDesc = s => status[s]
