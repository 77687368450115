import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import withPrivate from './HOC/withPrivate'
import Login from './login'
import Verify from './verify'
import Profile from './profile'
import CreateTask from './createTask'
import TaskList from './taskList'
import EditBoard from './editBoard'
import Report from './report'
import './index.css'

ReactDOM.render(
    <BrowserRouter>
        <Route exact path="/login" component={Login} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/createTask" component={withPrivate(CreateTask)} />
        <Route exact path="/user/profile" component={withPrivate(Profile)} />
        <Route exact path="/user/taskList" component={withPrivate(TaskList)} />
        <Route exact path="/board/edit" component={withPrivate(EditBoard)} />
        <Route exact path="/board/report" component={withPrivate(Report)} />
    </BrowserRouter>
, document.getElementById('root'));
